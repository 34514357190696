import React from 'react'
import { Link } from 'gatsby'
import cookieStyles from './cookies.module.scss'

function Cookies() {
    const [hasMounted, setHasMounted] = React.useState(false)
    React.useEffect(() => {
        setHasMounted(true)
    }, [])
    if (!hasMounted) {
        return null
    }

    const toggleContent = (e) => {
        e.preventDefault()
        localStorage.setItem("cookiepop", "true")
        setHasMounted(false)
    }

    const cookie = typeof window !== 'undefined' ? localStorage.getItem("cookiepop") : null

    if (cookie != "true") {
        return (
            <section className={`${cookieStyles.cookies} ${cookieStyles.cookies__active}`}>
                <div className={cookieStyles.cookies__container}>
                    <div className={cookieStyles.cookies__text}>
                        <p>We use cookies to enhance your experience, provide our services, deliver support, and make improvements.</p>
                    </div>

                    <div className={cookieStyles.cookies__buttons}>
                        <Link to="/privacy-policy/" className="button button--small button__transparent">Privacy Policy</Link>
                        <a href="" className="button button--small button__blue" onClick={toggleContent}>Got it!</a>
                    </div>
                </div>
            </section>
        )
    }
    return (
        null
    )
}

export default Cookies