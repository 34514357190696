import React from 'react'
import { Link } from 'gatsby'
import footerStyles from './footer.module.scss'
import JSONData from "./../data/eco.json"

const Footer = (props) => {
    
    return (
        <footer className={footerStyles.footer}>
            <div className={footerStyles.footer__wave}></div>
            <div className={"container " + footerStyles.footer__container}>
                <div className={footerStyles.footer__links}>
                    
                    <p>Discover</p>
                
                    <ul>
                        <li><Link to="/" activeClassName={footerStyles.active}>Home</Link></li>
                        <li><Link to="/why/" activeClassName={footerStyles.active}>Why Host3?</Link></li>
                        <li><Link to="/support/" activeClassName={footerStyles.active}>Support</Link></li>
                        <li><Link to="/contact/" activeClassName={footerStyles.active}>Contact Us</Link></li>
                        <li><a href="https://account.host3.co.uk">My Account</a></li>
                    </ul>

                    <p><Link to="/solutions/" activeClassName={footerStyles.active}>Solutions</Link></p>

                    <ul>
                        <li><Link to="/cloud-hosting/" activeClassName={footerStyles.active}>Cloud Hosting</Link></li>
                        <li><Link to="/wordpress-hosting/" activeClassName={footerStyles.active}>WordPress Hosting</Link></li>
                    </ul>
                </div>

                <div className={footerStyles.footer__legal}>
                    <p>Copyright &copy; 2008-{(new Date().getFullYear())} Host3. All rights reserved. <span><Link to="/terms-of-service/">Terms of Service</Link> | <Link to="/privacy-policy/">Privacy Policy</Link></span></p>
                    <p><Link to="/why/#sustainability"><span>{JSONData.trees}</span> trees planted <i className="icon icon-right-small"></i></Link></p>
                </div>
            </div>
        </footer>  
    )
}

export default Footer