import React from 'react'
import { Link } from 'gatsby'
import Store from '../components/store'
import Head from '../components/head'
import Navigation from '../components/navigation'
import Header from '../components/header'
import Layout from '../components/layout'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import blogStyles from '../components/blog.module.scss'
import Footer from '../components/footer'
import Cookies from '../components/cookies'

const BlogPage = () => {
    const data = useStaticQuery(graphql`
        query {
            allWordpressPost(sort: { fields: date, order: DESC }) {
                edges {
                    node {
                        excerpt
                        slug
                        title
                        date(formatString: "MMMM D, YYYY")
                    }
                }
            }
        }
    `)

    return (
        <Store>
            <Head title="Blog" />
            <Navigation />
            <Header disable />
            <Layout>
                <ul className={blogStyles.blog}>
                    {data.allWordpressPost.edges.map((edge, i) => {
                        
                        let postTitle

                        if (i < 1) {
                            postTitle = "<h1>" + edge.node.title + "</h1>"
                                
                        } else {
                            postTitle = "<h2>" + edge.node.title + "</h2>"
                        }

                        return (
                            <li className={i < 1 ? `${blogStyles.blog__featured}` : `${blogStyles.blog__post}`}>
                                <Link to={`/blog/${edge.node.slug}/`}>
                                    <div className={blogStyles.blog__image}>
                                        
                                    </div>
                                    <div className={i < 1 ? `${blogStyles.blog__featured__container}` : `${blogStyles.blog__post__container}`}>
                                        <div dangerouslySetInnerHTML={{ __html: postTitle }} />
                                        <div dangerouslySetInnerHTML={{ __html: edge.node.excerpt }} />
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                </ul>
            </Layout>
            <Footer />
            <Cookies />
        </Store>
    )
}

export default BlogPage